import React from "react";
import "../../sign-in-style.css";
import image from "../../assets/Archive/Login/business-merger/pana.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Input } from "@mantine/core";

function SignUpScreen() {
  const schema = yup.object().shape({
    username: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().min(8).max(32).required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username : '',
      email: '',
      password: '',
    },
  });

  const navigate = useNavigate();

  const onSubmitHandler = async (data) => {
    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/account";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        localStorage.setItem("cureSpaceUserLogged", JSON.stringify(true));
        // toast.success("User created successfully");
        // toast.success("Verification email sent successfully");
        navigate("/login");
      } else {
        const responseData = await response.json().catch(() => null);
        const errorMessage =
          responseData?.message || "Failed to register! Try again";
        toast.error(errorMessage[0]);
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 image-container">
          <img src={image} alt="" />
        </div>
        <div className="col-md-6 sign-in-screen">
          <div className="welcome-back">
            <h1>WELCOME</h1>
            <p>Sign Up to enjoy the portal benefits</p>
            <hr />
          </div>
          <form
            className="sign-in-form"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <label htmlFor="username">Username</label>
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <Input
                  type="text"
                  {...field}
                  invalid={!!errors.username?.message}
                  placeholder="Enter your Username"
                />
              )}
            />
            <div
              style={{
                height: "20px",
                color: "red",
                padding: "4px 2px",
                fontSize: "12px",
              }}
            >
              {errors?.username?.message as any}
            </div>
            <label htmlFor="email">Email</label>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input
                  type="text"
                  {...field}
                  invalid={!!errors.email?.message}
                  placeholder="Enter your Email"
                />
              )}
            />
            <div
              style={{
                height: "20px",
                color: "red",
                padding: "4px 2px",
                fontSize: "12px",
              }}
            >
              {errors?.email?.message as any}
            </div>
            <label htmlFor="password">Password</label>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input
                  type="password"
                  placeholder="Enter your Password"
                  {...field}
                  invalid={!!errors.password?.message}
                />
              )}
            />
            <div
              style={{
                height: "20px",
                color: "red",
                padding: "4px 2px",
                fontSize: "12px",
              }}
            >
              {errors?.password?.message as any}
            </div>
            <div className="forgot-password">
              <a href="#">Forgot your password?</a>
            </div>
            <button type="submit" className="sign-in-button">
              Sign Up
            </button>
          </form>
          <div className="sign-up">
            <hr />
            <p>
              Already have an account?{" "}
              <a  role="button" onClick={() => navigate("/login")}>
                <u>Sign In</u>
              </a>
            </p>
          </div>
          <div className="sign-up-other-option">
            <a role="button" onClick={() => navigate("/viewPackages")}>
              <u>VIEW PACKAGES</u>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpScreen;
