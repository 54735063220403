import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
  }));

const EmailVerify = () => {

    const {token} = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://api.thecarepro.co.uk/api/v1/account/verify/${token}`);
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log('Data:', data);
            toast.success("Verification successful");
            navigate("/login")
          } catch (error) {
            console.error('Error:', error);
            toast.error("Verification Failed");
            navigate("/")
            }
        };
    
        fetchData();
    
      }); 

      const classes = useStyles();


  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  )
}

export default EmailVerify
